<template>
  <b-form name="perfect-mind-form" id="perfect-mind-form" autocomplete="off" @submit.prevent="onSubmitPerfectMindForm" novalidate>
    <b-form-row v-for="(input, index) in formInputList" :key="index">
      <b-col cols="12" v-if="input.key == 'PerfectMind-APIKey' && isEditMode">
        <b-form-group>
          <b-form-checkbox v-model="updateAPIKeyChecked" @change="updateAPIKey" name="update-api-key-checked"> Update API key </b-form-checkbox>
          <p class="mb-0 ml-4 font-14">Enable updating of the API key.</p>
        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="input.key === 'PerfectMind-LocationFilter'">
        <b-form-group>
          Citizens can filter program and activities by location. Please enter the city name(s) for which the locations should be shown in the location filter.
          Separate multiple cities with comma (,)
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <div class="floating-input-field">
            <b-form-input
              v-if="input.isStored && !updateAPIKeyChecked && input.key == 'PerfectMind-APIKey'"
              :id="input.key"
              type="password"
              value="************"
              placeholder=" "
              autocomplete="new-password"
              disabled
            >
            </b-form-input>
            <b-form-input
              v-else
              :id="input.key"
              :type="input.key == 'PerfectMind-APIKey' ? 'password' : 'text'"
              placeholder=" "
              v-model.trim="input.value"
              :autocomplete="input.key == 'PerfectMind-APIKey' ? 'new-password' : 'off'"
              required
            ></b-form-input>
            <label :for="input.key">{{ input.label }}</label>
            <b-form-invalid-feedback class="d-block" v-if="!input.value && !input.isStored && input.required && formSubmitted"
              >{{ input.label }} required.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback class="d-block" v-if="!input.value && updateAPIKeyChecked && formSubmitted && input.key == 'PerfectMind-APIKey'"
              >{{ input.label }} required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-button type="submit" v-activeBlur variant="primary" :disabled="saveLoadingIcon" @click="perfectMindSidebarFg = true"
            >Save<b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner>
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>
<script>
import { HTTP } from '@/utilities/http-common'
import cloneDeep from 'lodash/cloneDeep'
import { ServiceUrls } from '@/utilities/serviceUrls'
export default {
  name: 'PerfectMindForm',
  props: ['tenantServiceId'],
  data() {
    return {
      apiDefaultPayload: null,
      formSubmitted: false,
      saveLoadingIcon: false,
      isValidConfiguration: false,
      updateAPIKeyChecked: false,
      isEditMode: false,
      savedConfigurations: [],
      defaultComponentId: '00000000-0000-0000-0000-000000000000',
      formInputList: [
        {
          id: null,
          label: 'Base domain',
          key: 'PerfectMind-BaseDomain',
          value: '',
          isStored: false,
          required: true,
          tenantProductGroupComponentId: null,
        },
        {
          id: null,
          label: 'API version',
          key: 'PerfectMind-APIVersion',
          value: '',
          isStored: false,
          required: true,
          tenantProductGroupComponentId: null,
        },
        {
          id: null,
          label: 'Client id',
          key: 'PerfectMind-ClientId',
          value: '',
          isStored: false,
          required: true,
          tenantProductGroupComponentId: null,
        },
        {
          id: null,
          label: 'API Key',
          key: 'PerfectMind-APIKey',
          value: '',
          isStored: false,
          required: true,
          tenantProductGroupComponentId: null,
        },
        {
          id: null,
          label: 'City name(s)',
          key: 'PerfectMind-LocationFilter',
          value: '',
          isStored: false,
          required: false,
          tenantProductGroupComponentId: null,
        },
      ],
    }
  },
  mounted() {
    this.updatePayload().then(() => this.getPerfectMindConfiguration())
  },
  methods: {
    updatePayload() {
      return new Promise((resolve, reject) => {
        this.apiDefaultPayload = {
          productId: 'PerfectMind',
          tenantSvcId: this.tenantServiceId,
        }
        resolve()
      })
    },
    assignDefaultValue() {
      this.isEditMode = false
      this.formInputList.forEach((input) => {
        input.tenantId = this.tenantServiceId
        input.value = ''
        input.isStored = false
        input.tenantProductGroupComponentId = this.defaultComponentId
      })
    },
    getPerfectMindConfiguration() {
      return HTTP.post(ServiceUrls.getCredentialByTenantId, this.apiDefaultPayload)
        .then((res) => {
          this.updateAPIKeyChecked = false
          if (res.data && res.data.data && res.data.data.length) {
            this.isEditMode = true
            this.formInputList.forEach((input) => {
              input.tenantId = this.tenantServiceId
              input.tenantProductGroupComponentId = this.defaultComponentId
              const inputObject = res.data.data.find((data) => data.key === input.key)
              if (inputObject) {
                input.value = inputObject.value
                input.isStored = inputObject.isStored
                input.tenantProductGroupComponentId = inputObject.tenantProductGroupComponentId
              }
              this.savedConfigurations = cloneDeep(this.formInputList)
            })
          } else {
            this.assignDefaultValue()
          }
          this.isValidConfiguration = !this.formInputList.filter((input) => !input.isStored && input.required && !input.value).length
          this.$emit('perfectMindIsValidConfiguration', this.isValidConfiguration)
          return res
        })
        .catch((error) => {
          this.assignDefaultValue()
          this.updateAPIKeyChecked = false
          this.$emit('perfectMindIsValidConfiguration', false)
          return Promise.reject(error)
        })
    },
    updateAPIKey() {
      const apiKeyIndex = this.formInputList.findIndex((input) => input.key === 'PerfectMind-APIKey')
      this.formInputList[apiKeyIndex].value = this.savedConfigurations[apiKeyIndex].value
    },
    validateForm() {
      return !this.formInputList.some((input) => {
        if (input.required) {
          return this.updateAPIKeyChecked ? !input.value : !input.isStored && !input.value
        } else {
          return false
        }
      })
    },
    async onSubmitPerfectMindForm() {
      this.formSubmitted = true
      if (this.validateForm()) {
        this.saveLoadingIcon = true
        const payload = {
          credsPayload: this.apiDefaultPayload,
          configData: this.formInputList,
        }
        return HTTP.post(ServiceUrls.updateCredentialByTenantId, payload)
          .then((res) => {
            this.saveLoadingIcon = false
            if (res.data && res.data.data) {
              this.getPerfectMindConfiguration()
            }
            this.$emit('perfectMindConfigUpdateRes', res.data)
            return res
          })
          .catch((error) => {
            this.saveLoadingIcon = false
            this.$emit('perfectMindConfigUpdateRes', false)
            return Promise.reject(error)
          })
      }
    },
  },
  watch: {
    tenantServiceId(newValue, oldValue) {
      this.updateHeaders().then(() => this.getPerfectMindConfiguration())
    },
  },
}
</script>
